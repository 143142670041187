<template>
  <section class="main">

    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <span class="table-header">Список клиентов</span>
      <span class="btn-group">
        <button class="btn btn-secondary" @click="UIshowFilter">Показать фильтры</button>
        <button class="btn btn-success" @click="openClientModal">Создать клиента</button>
      </span>
    </div>

    <div class="form-filter low fs-12" @keyup.enter="getUserList" v-if="visibleFalse">
      <div class="row">
        <span class="col-md-2 form-group">
            <label class="form-label">Поиск
              <vue-custom-tooltip label="Поиск по телефону, email или ФИО"><i class="far fa-question-circle"></i>
              </vue-custom-tooltip>
            </label>
            <input type="text" class="form-control" placeholder="Поиск..." v-model="filter.search"/>
        </span>
        <span class="col-md-2 form-group">
          <label class="form-label">Сколько раз заказывал</label>
          <input class="form-control" type="number" v-model="filter.acceptOrder">
        </span>
        <span class="col-md-2 form-group">
          <label class="form-label">Последний заказ от</label>
          <input class="form-control" type="datetime-local" v-model="filter.orderDateBefore">
        </span>
        <span class="col-md-2 form-group">
          <label class="form-label">Последний заказ до</label>
          <input class="form-control" type="datetime-local" v-model="filter.orderDateAfter">
        </span>
        <span class="col-md-2 form-group">
          <label class="form-label">Средний чек от</label>
          <input class="form-control" type="number" v-model="filter.avgCheckBefore">
        </span>
        <span class="col-md-2 form-group">
          <label class="form-label">Средний чек до</label>
          <input class="form-control" type="number" v-model="filter.avgCheckAfter">
        </span>
        <span class="col-md-2 form-group">
          <label class="form-label">Заказов &gt;</label>
          <input class="form-control" type="number" v-model="filter.ordersMoreThen">
        </span>
        <span class="col-md-2 form-group d-flex flex-column">
          <span class="form-label">Фильтр по товару 
              {{ this.filter.productIDFromChildren > 0 ? ' | ID:' + this.filter.productIDFromChildren : '' }}
            <vue-custom-tooltip label="Поиск пользователей, которые купили конкретный товар"><i
                class="far fa-question-circle"></i>
            </vue-custom-tooltip>
          </span>
          <button v-if="+this.filter.productIDFromChildren === 0" type="button" class="btn btn-primary fs-12"
                  @click="showModalListProcutcs">Выбрать товар</button>
          <button v-else type="button" class="btn btn-secondary fs-12" @click="resetProductId">Сбросить выбор</button>
        </span>
      </div>
      <span class="d-flex justify-content-between mt-2">
        <span class="btn-group">
          <button type="button" class="btn btn-secondary" @click="exportExcel">XLSX</button>
          <button type="button" class="btn btn-dark" @click="UIgetInfo">ⓘ</button>
        </span>
        <button type="button" class="btn btn-success" @click="getUserList">Найти</button>
      </span>
    </div>

    <table class="table table-hover table-bordered fs-12 mt-2">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">ФИО</th>
        <th scope="col">Телефон</th>
        <th scope="col">Адрес</th>
        <th scope="col">Email рассылка
          <vue-custom-tooltip label="Получает ли клиент рассылку"><i class="far fa-question-circle"></i>
          </vue-custom-tooltip>
        </th>
        <th scope="col">Кол-во заказов</th>
        <th scope="col">Средний чек</th>
        <th scope="col">Дата последнего заказа</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item) in userList" :key="item.id">
        <th scope="row">{{ item.id }}</th>
        <td>
          <router-link class="link-primary" :to="'/view-user/' + item.id">
            <span v-if="item.full_name.trim().length > 0">{{ item.full_name }}</span>
            <span v-else>-</span>
          </router-link>
        </td>
        <td>{{ item.phone }}</td>
        <td>
          <template v-if="item.userAddresses.length > 0 && item.userAddresses[0]!==''">
            {{ item.userAddresses[0].address }} <a class="link-primary"
                                                   :href="'https://yandex.ru/maps/?ll=' + item.userAddresses[0].lon + '%2C' + item.userAddresses[0].lat + '&mode=whatshere&whatshere%5Bpoint%5D=' + item.userAddresses[0].lon + '%2C' + item.userAddresses[0].lat + '&whatshere%5Bzoom%5D=11.97&z=7.78'"
                                                   target="_blank">({{ item.userAddresses[0].lat }},
            {{ item.userAddresses[0].lon }})</a>
          </template>
          <template v-else>-</template>
        </td>
        <td>
          <div class="form-check">
            {{ item.email }}
            <input class="form-check-input" type="checkbox" :checked="parseInt(item.adv_messages)"
                   @change="changeAdvMessage(item.id)">
          </div>
        </td>
        <td>{{ item.total_order_count }}</td>
        <td>
          {{ item.avg_check }}
        </td>
        <td>
          <template v-if="item.total_order_count > 0">
            {{ item.last_order_date }}
            <br>
            <span class="fs-12">Дней назад: {{ calculateLastOrder(item.last_order_date) }}</span>
          </template>
          <template v-else>-</template>
        </td>
        <td class="actions">
          <router-link :to="'/view-user/' + item.id">
            <i class="fas fa-eye"></i>
          </router-link>
          <button class="pointer" @click="openEditClientModal(item)">
            <i class="fas fa-pen"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="row">
      <div class="col text-center">
        <button class="btn btn-primary" @click="getMore">Загрузить ещё</button>
      </div>
    </div>

    <create-user/>

    <edit-user/>

    <select-products
        v-show="this.$store.state.modal.selectProducts"
        @close="closeModalListProcutcs"
        @setIDToParent="getIDFromChild"
    />

  </section>
</template>

<script>
import createUser from '../../components/modal/users/create-user';
import {mapGetters} from 'vuex';
import EditUser from "../../components/modal/users/edit-user";
import SelectProducts from "../../components/modal/users/select-products";

export default {
  name: 'list-users',
  components: {EditUser, createUser, SelectProducts},
  data: function () {
    return {
      filter: {
        search: '',
        acceptOrder: new Number(0),
        orderDateBefore: '',
        orderDateAfter: '',
        avgCheckBefore: new Number(0),
        avgCheckAfter: new Number(0),
        ordersMoreThen: new Number(0),
        productIDFromChildren: new Number(0),
        visible: 12,
      },
      isModalCreateUser: false,
      visibleFalse: false,
    }
  },
  computed: {
    ...mapGetters(['userList']),
    averageCheck: function () {
      return this.userList.map(function (item) {
        let result = 0;
        if (item.orders.length > 0) {
          let amount = 0;
          item.orders.forEach((val) => {
            amount += parseFloat(val.full_amount);
          });
          result = parseFloat(amount / item.orders.length).toFixed(2);
        }
        return result;
      });
    },
  },
  async mounted() {
    this.getUserList();
    this.$root.$on('updateClientList', () => {
      this.getUserList();
    });
  },
  methods: {
    calculateLastOrder: function (date) {
      if (date) {
        var date1 = new Date(date);
        var date2 = new Date();
        var daysLag = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
        return daysLag;
      }
    },
    changeAdvMessage: function (id) {
      this.$store.dispatch('changeAdv', {'id': id});
    },
    getLastDate: function () {
      var dateOffset = (24 * 60 * 60 * 1000) * 30;
      var timestamp = new Date();
      var todayDate = new Date(timestamp + dateOffset).toISOString().slice(0, -1);
      return todayDate;
    },
    getMore: function () {
      this.filter.visible += 12;
      this.getUserList();
    },
    getUserList: function () {
      this.$store.dispatch('getUserList', this.filter);
    },
    openClientModal: function () {
      this.$store.dispatch('openModal', 'createUser');
    },
    openEditClientModal: function (item) {
      this.$root.$emit('selectedClient', item);
      this.$store.dispatch('openModal', 'editUser');
    },
    exportExcel: function () {
      if (
          this.filter.avgCheckAfter.length > 0 &&
          this.filter.avgCheckBefore.length > 0 &&
          !Number.isInteger(+this.filter.avgCheckAfter) &&
          !Number.isInteger(+this.filter.avgCheckBefore)
      ) {
        this.$toast.info('Для начала необходимо ввести "Средний чек от" и "Средний чек до" <br> Затем нажать кнопку "Найти"');
        return;
      }
      if (
          this.filter.ordersMoreThen.length > 0 &&
          !Number.isInteger(+this.filter.ordersMoreThen)
      ) {
        this.$toast.info('Для начала необходимо ввести "Заказов >".<br> Затем нажать кнопку "Найти"');
        return;
      }
      // Передаём фильтры на BackEnd
      this.$store.dispatch('getUsersExport', this.filter).then(res => {
        if (res.data.result == 'ok') {
          const link = document.createElement('a');
          link.href = res.data.data.link;
          link.setAttribute('download', res.data.data.filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.$toast.success(res.data.message);
        } else {
          this.$toast.info(res.data.message);
        }
      });
    },
    UIgetInfo: function () {
      this.$toast.info(`Ограничение на кол-во пользователей: 99 тыс.
        <br>Если больше, будьте добры, скорректируйте фильтры.
        <br>Поиск, даты - не учитываются.
      `);
    },
    UIshowFilter: function () {
      this.visibleFalse = !this.visibleFalse;
    },
    showModalListProcutcs() {
      this.$store.state.modal.selectProducts = true;
    },
    closeModalListProcutcs() {
      this.$store.state.modal.selectProducts = false;
    },
    getIDFromChild(id) {
      this.filter.productIDFromChildren = id;
      this.getUserList();
    },
    resetProductId() {
      this.filter.productIDFromChildren = new Number(0);
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  &:not(.btn) {
    border: 0;
    background-color: transparent;
  }
}
</style>
